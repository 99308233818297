/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  background-color: white;
  opacity: 100%;
  color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

#root {
  width: 100%;
}

.white-divider {
  border-right: 4px white solid;
  border-bottom: none;
}

.feature-set {
  padding: 16px;
}

section.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 110px 132px; /* Padding top, left, and right */
  gap: 132px; /* Gap between left and right sections */
  width: 100vw;
  height: 100vh;
  box-sizing: border-box; /* Ensure padding is included in width and height */
  background-color: #142b6f;
}

/* Left Section */
.left-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: calc(50% - 66px); /* Adjusting width to account for the gap */
}

.left-section .logo {
  width: 640px;
  height: auto;
}

.left-section .tagline {
  width: 640px;
  font-size: 36px;
  line-height: 46.8px;
  max-width: 90vw;
}

.left-section p {
  font-size: 36px;
  line-height: 46.8px;
  margin: 20px 0;
}

.left-section .app-store-badge {
  width: 155.56px;
  height: 52px;
  cursor: pointer;
}

.features {
  padding: 110px 132px;
}

/* Features Section */
.features .feature-set {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;
  box-sizing: border-box;
  background-color: #f7f6f6;
  color: #b7c8f9;
  border: none;
  border-radius: 16px;
}

.feature {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.wavy-divider {
  width: 100vw;
  z-index: 1;
}

.feature img {
  width: 80px;
  height: auto;
  margin-bottom: 12px;
}

.feature h3 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: black;
}

.feature p {
  font-family: Inter;

  font-size: 18px;
  line-height: 24px;
  color: #58595c;
}

/* App Store Button */
.app-store-badge {
  width: 155.56px;
  height: 52px;
  cursor: pointer;
}

/* CTA Section */
.cta {
  padding-top: 20px;
  text-align: center;
  background-color: white;
  color: #141718;
}

.cta p {
  font-size: 24px;
  margin-bottom: 20px;
}

.cta img {
  width: 155.56px;
  height: 52px;
}
.cta-mobile {
  background-color: #142b6f;
  color: white !important;
}

/* Footer */
footer {
  padding: 24px;
  background-color: black;
  color: white;
  text-align: center;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer a {
  color: white;
  margin: 0 12px;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* Right Section */
.right-section {
  position: relative;
  width: calc(50% - 66px); /* Adjusting width to account for the gap */
  height: 100%; /* Ensure full height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.device-knockout {
  height: 100%;
  box-shadow: 0px 0px 50.18px 0px #58595c4d;
  max-width: 383px;
  max-height: 781px;
  border-radius: 50px;
  z-index: 2;
}

/* Right Section (Video) */
section.hero video {
  height: calc(100% - 14px);
  max-width: 354px !important;
  max-height: 748px !important;
  border-radius: 42.34px !important;
  position: absolute;
  z-index: 1; /* Ensure video is on top of the knockout image */
}

/* Responsive Layout */
@media (max-width: 1919px) {
  section.hero {
    padding: 40px 80px; /* Reduced padding for smaller screens */
    gap: 80px; /* Gap between left and right sections */
  }

  .features {
    padding: 40px 80px !important;
  }

  .left-section .logo {
    width: 640px;
    height: auto;
  }

  .left-section .tagline {
    width: 640px;
    font-size: 36px;
    line-height: 46.8px;
  }

  .left-section,
  .right-section {
    width: calc(50% - 40px);
  }
}

@media (max-width: 1520px) {
  .features {
    padding: 40px 80px !important;
  }
  section.hero {
    padding: 40px 80px; /* Reduced padding for smaller screens */
  }

  .left-section .logo {
    width: 563px;
    height: auto;
  }

  .left-section .tagline {
    width: 563px;
    font-size: 32px;
    line-height: 41.6px;
  }

  .left-section,
  .right-section {
    width: calc(50% - 40px);
  }
}

@media (max-width: 1250px) {
  .left-section .logo {
    width: 450px;
    height: auto;
  }

  .left-section .tagline {
    width: 450px;
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 1024px) {
  section.hero {
    flex-direction: column;
    padding: 0px;
    gap: 20px;
    height: auto;
    width: 100%;
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 48px;
  }

  .white-divider {
    border-bottom: 4px white solid;
    border-right: none;
  }

  .cta p {
    font-size: 22px;
    margin-bottom: 20px;
    line-height: 32px;
  }

  .features {
    padding: 80px 20px !important;
  }

  .feature img {
    width: 52.5px;
  }

  .wavy-divider {
    margin-top: -2px;
    width: 100vw;
    position: relative;
    z-index: 10;
  }

  .left-section,
  .right-section {
    width: 100%;
    max-width: 100%;
  }

  .left-section {
    align-items: center;
    text-align: center;
    padding: 48px 0px;
  }
  .right-section {
    padding: 0px;
    margin: 0px;
  }

  section.hero video {
    max-width: 812px !important;
    max-height: 2000px !important;
    height: auto;
    width: calc(90.7% - 76px);
    border-radius: 80px;
  }

  .left-section .app-store-badge {
    width: 119.4px;
    height: 40px;
    cursor: pointer;
  }

  .device-knockout {
    position: relative;
    z-index: 2;
    max-width: 900px !important;
    max-height: 2000px !important;
    height: auto;
    width: calc(100% - 76px);
  }

  .features .feature-set {
    grid-template-columns: 1fr;
    padding: 32px 16px;
  }

  .feature {
    padding: 16px;
  }

  .feature h3 {
    font-size: 26px;
    list-style: 31px;
    margin: 14px 0px;
  }

  .feature p {
    font-size: 14px;
  }

  .cta img {
    width: 119.4px;
    height: 40px;
  }

  footer {
    font-size: 14px;
    line-height: 28px;
  }
}
@media (max-width: 900px) {
  section.hero video {
    z-index: 1;
    max-width: 860px !important;
    max-height: 2000px !important;
    height: auto;
    width: calc(91% - 76px);
    border-radius: 80px;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .left-section {
    padding: 48px 0px;
  }

  .right-section {
    padding: 0px;
    margin: 0px;
  }
  /* .landing-page {
    padding: 48px 32px 48px 32px;
  } */

  .logo {
    width: 257px !important;
  }

  .tagline {
    font-size: 22px !important;
    line-height: 32px !important;
    width: 279px !important;
  }

  section.hero video {
    z-index: 1;
    max-width: 900px !important;
    max-height: 2000px !important;
    height: auto;
    width: calc(92% - 76px);
  }
}

@media (max-width: 300px) {
  section.hero video {
    z-index: 1;
    max-width: 900px !important;
    max-height: 2000px !important;
    height: auto;
    width: calc(93% - 76px);
  }
}

@media (max-width: 390px) {
  section.hero video {
    z-index: 1;
    max-width: 900px !important;
    max-height: 2000px !important;
    height: auto;
    width: calc(94% - 76px);
  }
}
