/* Footer */
footer {
  padding: 24px;
  background-color: black;
  color: white;
  text-align: center;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer a {
  color: white;
  margin: 0 12px;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

section.static-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 110px 132px; /* Padding top, left, and right */
  gap: 132px; /* Gap between left and right sections */
  width: 100vw;
  height: calc(100vh - 92px); /* Full height minus footer height */
  box-sizing: border-box; /* Ensure padding is included in width and height */
  background-color: #f8f8f8;
}

.left-static-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: calc(70% - 66px); /* Adjusting width to account for the gap */
}

.static-logo {
  width: 360px;
  height: auto;
}

.device-user {
  width: 100%;
  min-width: 400px;
  max-height: 80vh;
}

.urgent-help-img {
  width: 50%;
  min-width: 500px;
}

.mobile-panic-help-img {
  height: 30vh;
}
.mobile-urgent-help-img {
  height: 30vh;
}

.panic-help-img {
  width: 50%;
  min-width: 400px;
}
.left-static-section iframe {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  footer {
    font-size: 14px;
    line-height: 28px;
  }
  .left-static-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; /* Adjusting width to account for the gap */
  }

  section.static-section {
    padding: 0px; /* Padding top, left, and right */
    margin-left: 0px;
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {
  footer {
    font-size: 14px;
    line-height: 28px;
  }

  .left-static-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  section.static-section {
    padding: 0px;
    margin-left: 0px;
  }
}

/* Dark Theme */
@media (prefers-color-scheme: dark) {
  footer {
    background-color: #f8f8f8;
    color: #141718;
  }

  footer a {
    color: #141718;
  }

  section.static-section {
    background-color: #141718;
    color: white;
  }

  .left-static-section {
    color: white;
  }
}
